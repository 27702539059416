 import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CoreModule } from './@core/core.module';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForbiddenInterceptor } from './@core/interceptor/forbidden-interceptor';

import { AppComponent } from './app.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


// import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';

// export var MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
// 	connectOnCreate: false,
// };

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		AppRoutingModule,
		CoreModule,
		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		MatSlideToggleModule,
		// MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
		{ provide: MAT_DATE_LOCALE, useValue: 'ms' },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
