import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './@core/guards/auth.guard';

const routes: Routes = [
	{
		path: 'private',
		canActivate: [AuthGuard],
		loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)
	}, {
		path: 'public',
		loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
	}, {
		path: '',
		redirectTo: 'private',
		pathMatch: 'full'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
