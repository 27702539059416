import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { User } from '../models/user';
import { AuthService } from './auth.service';

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';


@Injectable({
	providedIn: 'root'
})
export class UserService implements Resolve<User[]> {
	private _users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

	private _roles = [
		// 'root',
		'partner',
		'client',
		'contractor',
	]

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private spinnerService: SpinnerService,
	) { }

	async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User[]> {
		if (this.users.length) return this.users

		return await this.getUser()
	}

	get users() {
		return this._users.value;
	}

	get roles() {
		return this._roles;
	}

	async destroy() {
		this._users.next([])
	}

	find(user: User | string) {
		if (typeof user === 'string')
			return this.users.find(x => x._id == user);

		return user
	}

	observer(): Observable<User[]> {
		return this._users;
	}

	async getUser() {
		try {
			this.spinnerService.showSpinner();

			const httpOptions = {
				withCredentials: true,
			};

			let res: User[] = await this.http.get<User[]>(`${environment.api}/user`, httpOptions).toPromise();

			this.spinnerService.hideSpinner();

			if (res) {
				this._users.next(res);
			}
			return res

		} catch (e) {
			this.spinnerService.hideSpinner();
			console.error("![UserService] getUser failed", e.message)

			throw e
		}
	}

	async createUser(user: User) {
		try {
			this.spinnerService.showSpinner();

			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
				}),
				withCredentials: true,
			};

			let res: User = await this.http.post<User>(`${environment.api}/user`, user, httpOptions).toPromise();

			this.spinnerService.hideSpinner();

			if (res && res._id) {
				this._users.next([
					...this.users,
					res
				])
			}
			return res;

		} catch (e) {
			this.spinnerService.hideSpinner();
			throw e.error ? e.error : e;
		}
	}

	async updateUser(user: User) {
		try {
			this.spinnerService.showSpinner();

			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
				}),
				withCredentials: true,
			};

			let res: any = await this.http.patch<any>(`${environment.api}/user/${user._id}`, user, httpOptions).toPromise();

			this.spinnerService.hideSpinner();

			console.log('[UserService] updateUser', res);

			if (res && res.nModified > 0) {
				let i = this.users.findIndex(x => x._id == user._id)

				if (i >= 0) {
					this.users[i] = {
						...this.users[i],
						...user
					}
				}
				this._users.next(this.users);

				return this.users[i];
			}
			return null;

		} catch (e) {
			this.spinnerService.hideSpinner();
			throw e.error ? e.error : e;
		}
	}

	// async updateRole(user: User, role: string) {
	// 	try {
	// 		this.spinnerService.showSpinner();

	// 		const httpOptions = {
	// 			headers: new HttpHeaders({
	// 				'Content-Type': 'application/json',
	// 			}),
	// 			withCredentials: true,
	// 		};

	// 		let res: User = await this.http.patch<User>(`${environment.api}/user/role/${user._id}`, { role: role }, httpOptions).toPromise();

	// 		this.spinnerService.hideSpinner();

	// 		return res;

	// 	} catch (err) {
	// 		this.spinnerService.hideSpinner();
	// 		throw err;
	// 	}
	// }

	async deleteUser(user: User) {
		try {
			this.spinnerService.showSpinner();

			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
				}),
				withCredentials: true,
			};

			let res: any = await this.http.delete<any>(`${environment.api}/user/${user._id}`, httpOptions).toPromise();

			this.spinnerService.hideSpinner();

			console.log('[UserService] deleteUser', res);

			if (res && res._id) {
				let i = this.users.findIndex(x => x._id == res._id)

				if (i >= 0) {
					this.users.splice(i, 1)
					this._users.next(this.users)
				}
			}
			return res;

		} catch (e) {
			this.spinnerService.hideSpinner();
			throw e.error ? e.error : e;
		}
	}

	async loginPin(pin: string, userId: string) {
		this.spinnerService.showSpinner();

		const body = { 'PIN': pin };

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			withCredentials: true
		};

		try {
			let res = await this.http.post<User>(`${environment.api}/user/login/pin/${userId}`, body, httpOptions).toPromise();

			console.log("[UserService] login pin", res);

			this.spinnerService.hideSpinner();

			if (res) {
				return res;
			}

			return null;

		} catch (err) {
			console.log("![UserService] login pin failed", err);

			this.spinnerService.hideSpinner();
			throw err;
		}
	}
	// async createUser(user: User) {
	//   try {
	//     this.spinnerService.showSpinner();

	//     const httpOptions = {
	//       headers: new HttpHeaders({
	//         'Content-Type': 'application/json',
	//       }),
	//       withCredentials: true,
	//     };

	//     let body: string = `mutation { createUser(req: { name:"${user.name}", usern:"${user.usern}", email:"${user.email}" }){_id}}`;

	//     let res: any = await this.http.post<any>('http://localhost:3000/graphql', body, httpOptions).toPromise();

	//     this.spinnerService.hideSpinner();

	//     if (res && res.data) {
	//       if (res.data.createUser) {
	//         user._id = res.data.createUser._id

	//         this.subject.next([
	//           ...this.users(),
	//           user
	//         ])

	//         return res.data.createUser;
	//       }
	//     }
	//     return null;

	//   } catch (err) {
	//     this.spinnerService.hideSpinner();

	//     throw err;
	//   }
	// }
}
