import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '../models/user';

import { SpinnerService } from './spinner.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	//private roleSubject: BehaviorSubject<string[]>;
	isLogoutSubject: BehaviorSubject<boolean>;
	private userSubject: BehaviorSubject<User>;
	private _editor = false;
	//userRoles: string[] = null;

	constructor(
		private http: HttpClient,
		private spinnerService: SpinnerService
	) {
		this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
		this.isLogoutSubject = new BehaviorSubject<boolean>(false);
	}

	auth() {
		return this.userSubject.asObservable();
	}

	get user(): User {
		return this.userSubject.value;
	}

	get editor() {
		return this._editor;
	}

	set editor(authorized: boolean) {
		this._editor = authorized;
	}

	async login(username: string, password: string) {
		this.spinnerService.showSpinner();

		const body = { 'username': username, 'password': password };

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			withCredentials: true
		};

		try {
			let res: User = await this.http.post<User>(`${environment.api}/login`, body, httpOptions).toPromise();

			console.log("[AuthService] logon", res);

			this.spinnerService.hideSpinner();

			if (res) {
				localStorage.setItem('currentUser', JSON.stringify(res));
				this.userSubject.next(res);

				return res;
			}
			return null;

		} catch (err) {
			console.log("![AuthService] login failed", err);

			this.spinnerService.hideSpinner();
			throw err;
		}
	}

	async token(client: string, token: string, production: boolean) {
		this.spinnerService.showSpinner();

		const body = { 'client': client, 'token': token, 'production': production };

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			withCredentials: true
		};

		try {
			let res: User = await this.http.post<User>(`${environment.api}/token`, body, httpOptions).toPromise();

			this.spinnerService.hideSpinner();

			if (res) {
				localStorage.setItem('currentUser', JSON.stringify(res));
				this.userSubject.next(res);

				return res;
			}
			return null;

		} catch (err) {
			console.log("![AuthService] login failed", err);

			this.spinnerService.hideSpinner();
			throw err;
		}
	}

	// async forgotPassword(username: string) {
	// 	this.spinnerService.showSpinner();

	// 	const httpOptions = {
	// 		headers: new HttpHeaders({
	// 			'Content-Type': 'application/json'
	// 		}),
	// 	};

	// 	try {
	// 		let res = await this.http.post<any>(environment.api + '/password', { username: username }, httpOptions).toPromise();

	// 		console.log(res);
	// 		this.spinnerService.hideSpinner();

	// 		return res;
	// 	} catch (err) {
	// 		this.spinnerService.hideSpinner();
	// 		throw err;
	// 	}
	// }

	// async validatePassword(token: string) {
	// 	this.spinnerService.showSpinner();

	// 	const httpOptions = {
	// 		headers: new HttpHeaders({
	// 			'Content-Type': 'application/json'
	// 		}),
	// 	};

	// 	try {
	// 		let res = await this.http.post<any>(environment.api + '/password/' + token, httpOptions).toPromise();

	// 		console.log(res);
	// 		this.spinnerService.hideSpinner();

	// 		return res;
	// 	} catch (err) {
	// 		this.spinnerService.hideSpinner();
	// 		throw err;
	// 	}
	// }

	// async resetPassword(token: string, password: string) {
	// 	this.spinnerService.showSpinner();

	// 	const httpOptions = {
	// 		headers: new HttpHeaders({
	// 			'Content-Type': 'application/json'
	// 		}),
	// 	};

	// 	try {
	// 		let res = await this.http.patch<any>(environment.api + '/password/' + token, { password: password }, httpOptions).toPromise();

	// 		console.log(res);
	// 		this.spinnerService.hideSpinner();

	// 		return res;
	// 	} catch (err) {
	// 		this.spinnerService.hideSpinner();
	// 		throw err;
	// 	}
	// }

	// async role() {
	// 	try {
	// 		// let body: string = `query { roles }`;

	// 		// const httpOptions = {
	// 		//   headers: new HttpHeaders({
	// 		//     'Content-Type': 'application/graphql',
	// 		//   }),
	// 		//   withCredentials: true,
	// 		// };

	// 		// let res: any = await this.http.post<any>('http://localhost:3000/api', body, httpOptions).toPromise();

	// 		// console.log("[AuthService] roles", res);

	// 		// if (res && res.data && res.data.roles) {
	// 		//   this.roleSubject.next(res.data.roles);
	// 		//   //this.userRoles = res.data.roles;
	// 		//   //return (this.userRoles.indexOf(role) >= 0);
	// 		// }

	// 		//return false;


	// 		const httpOptions = {
	// 			// headers: new HttpHeaders({
	// 			//   'Content-Type': 'application/graphql',
	// 			// }),
	// 			withCredentials: true,
	// 		};

	// 		let res: any = await this.http.get<any>(`${environment.api}/auth/${this.user._id}`, httpOptions).toPromise();
	// 		console.log("[AuthService] role", res);

	// 		// if (res && res.data && res.data.roles) {
	// 		//   this.roleSubject.next(res.data.roles);
	// 		//   //this.userRoles = res.data.roles;
	// 		//   //return (this.userRoles.indexOf(role) >= 0);
	// 		// }
	// 	} catch (err) {
	// 		// if (err && err.error && err.error.errors) {
	// 		//   throw err.error.errors[0];
	// 		// }
	// 		throw err;
	// 	}
	// }

	// root() {
	// 	if (this.user && this.user.role)
	// 		return (this.user.role === 'root') ? '/u' : ''
	// 	else
	// 		throw new Error('Invalid User')
	// }

	authorize(role: string) {
		if (this.user && this.user.role)
			return (this.user.role === 'root' || this.user.role === role);
		else
			throw new Error('Invalid User');
	}

	async updatePassword(password: { current: string, new: string; }) {
		this.spinnerService.showSpinner();

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			withCredentials: true,
		};

		try {
			let res = await this.http.patch<any>(`${environment.api}/user/password/${this.user._id}`, password, httpOptions).toPromise();

			console.log('[AuthService] updatePassword', res);

			this.spinnerService.hideSpinner();

			return res;
		} catch (err) {
			this.spinnerService.hideSpinner();
			throw err;
		}
	}

	async logout() {
		try {
			this.spinnerService.showSpinner();

			const body = {};

			const httpOptions = {
				withCredentials: true,
			};

			let res = await this.http.post<any>(`${environment.api}/logout`, body, httpOptions).toPromise();

			localStorage.removeItem('currentUser');
			this.userSubject.next(null);

			this.spinnerService.hideSpinner();

			return res;
		} catch (err) {
			console.log("![AuthService] logout failed", err);
			this.spinnerService.hideSpinner();
			throw err;
		}
	}
}
