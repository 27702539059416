import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private router: Router,
		private authService: AuthService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.authService.user) {
			if (route.data.roles) {
				if (!route.data.roles.find((role: string) => this.authService.authorize(role))) {
					this.router.navigate([state.url.substr(0, state.url.lastIndexOf('/'))]);
					return false;
				}
			}
			// logged in so return true
			return true;
		}

		// not logged in so redirect to login page with the return url
		console.log("[AuthGuard] user not login");
		this.router.navigate(['/public']);
		return false;
	}
}
