import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { zIndex } from 'html2canvas/dist/types/css/property-descriptors/z-index';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  message: string = 'An unspecified error has occurred';
  icon: string = '';
  type: string = ''
  buttonText = 'Ok';
  dialogRef;
  @ViewChild('dialogTemplate', { static: true }) myTemplateRef!: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private alertDialogService: AlertDialogService,
  ) { }

  ngOnInit(): void {
    this.openAlert()
    setTimeout(() => {
      this.closeDialog() 
    }, 2000);
  }

  getAlertClass() {
    // Add conditions based on your specific requirements to determine the CSS class dynamically.
    if (this.type === 'error') {
      return 'alert alert-danger'; // Use 'alert-danger' class for error type.
    } else if (this.type === 'warning') {
      return 'alert alert-warning'; // Use 'alert-warning' class for warning type.
    } else {
      return 'alert alert-success'; // Default to 'alert-success' class for other types.
    }
  }


  closeDialog() {
    this.dialogRef.close();
  }

  openAlert() {
    this.icon = this.alertDialogService.icon;
    this.type = this.alertDialogService.type;
    this.message = this.alertDialogService.message;

    this.alertDialogService.hideAlert()
    let info = {
      disableClose: false,
      backdropClass: "alert-dialog",
      position: {
        translate:'(-50% , -50%)',
        top: '60px'
      },
    }
    this.dialogRef = this.dialog.open(this.myTemplateRef, info)
    this.dialogRef.afterClosed().subscribe(res => {
      this.alertDialogService.hideAlert()
    })
  }

}
