import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";

import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';

import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTreeModule } from '@angular/material/tree'
// import { FlexLayoutModule } from '@angular/flex-layout';
// import { DragDropModule } from '@angular/cdk/drag-drop';

import { UserPipe } from "./pipes/user.pipe";
import { JunctionPipe } from "./pipes/junction.pipe";
import { CameraPipe } from './pipes/camera.pipe';
import { AlertDialogComponent } from './alert-dialog/alert-dialog/alert-dialog.component';


@NgModule({
	declarations: [
		UserPipe,
		CameraPipe,
		JunctionPipe,
  AlertDialogComponent,
	],
	imports: [
		CommonModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatGridListModule,
		MatInputModule,
		MatIconModule,
		MatMenuModule,
		MatTableModule,
		MatSortModule,
		MatTabsModule,
		MatToolbarModule,
		MatSelectModule,
		MatSidenavModule,
		MatProgressSpinnerModule,
		MatRadioModule, //MatOptionModule,
		MatProgressBarModule,
		MatTableModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatDividerModule,
		MatListModule,
		MatChipsModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatSliderModule,
		MatStepperModule,
		MatTreeModule,
		LayoutModule,
		OverlayModule,
		// DragDropModule,
		// FlexLayoutModule,
	],
	exports: [
		UserPipe,
		CameraPipe,
		JunctionPipe,
		CommonModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatGridListModule,
		MatInputModule,
		MatIconModule,
		MatMenuModule,
		MatTableModule,
		MatSortModule,
		MatTabsModule,
		MatToolbarModule,
		MatSelectModule,
		MatSidenavModule,
		MatProgressSpinnerModule,
		MatRadioModule, //MatOptionModule,
		MatProgressBarModule,
		MatTableModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatDividerModule,
		MatListModule,
		MatChipsModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatSliderModule,
		MatStepperModule,
		MatTreeModule,
		LayoutModule,
		OverlayModule,
		// DragDropModule,
		// FlexLayoutModule,
	],
})

export class CoreModule { }