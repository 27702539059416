import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, throwError, Observable, BehaviorSubject, filter, take, switchMap, tap, lastValueFrom, NEVER, EMPTY } from 'rxjs';
import { Router } from '@angular/router';

export abstract class AuthInterceptorService {
  public abstract authControllerRefresh(): Observable<{ token: string; }>;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private accessToken: string | null = null;
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    protected authService: AuthInterceptorService,
    protected router: Router,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log("[AuthInterceptor] intercept url", request.url);

    let headers = request.headers

    if (this.accessToken) {
      headers = headers.set("Authorization", "Bearer " + this.accessToken)
    }

    const cloned = request.clone({
      headers,
      withCredentials: (request.url.includes("login") || request.url.includes("refresh"))
    });


    // console.log("[AuthInterceptor] cloned", cloned.headers);
    return next.handle(cloned).pipe(
      tap(event => {
        // Catch login event and store access token
        if (event instanceof HttpResponse && event.status === 200 && request.url.includes("login")) {
          this.accessToken = event?.body?.token;
          // console.log("[AuthInterceptor] parsing access-token from login response", this.accessToken);
        }

        if (event instanceof HttpResponse && event.status === 200 && request.url.includes("logout")) {
          this.accessToken = null;
          document.cookie = "rt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          // console.log("[AuthInterceptor] clearing access-token after logout", this.accessToken);
        }
      }),
      catchError(error => {
        // console.log('[AuthInterceptor] error caught', error);
        if (request.url.includes("login"))
          return throwError(() => this.handleError(error));

        if (request.url.includes("refresh")) {
          console.log("[AuthInterceptor] refresh token failed", error);

          this.accessToken = null;
          this.refreshTokenInProgress = false;

          document.cookie = "rt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          this.router.navigate(['public']);

          alert("Your session has expired. Please login again.");
          return NEVER;
        }

        if (error instanceof HttpErrorResponse && error.status === 401) {
          console.log("[AuthInterceptor] handling HttpErrorResponse 401", error);
          return this.handle401Error(request, next);
        }

        return throwError(() => this.handleError(error));
      }))
  }

  private handle401Error(req: HttpRequest<unknown>, next: HttpHandler) {
    console.log("[AuthInterceptor] handle401Error", this.refreshTokenInProgress, req.url);

    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;

      console.log("[AuthInterceptor] refreshing token");

      lastValueFrom(
        this.authService.authControllerRefresh()).then(res => {
          console.log("[AuthInterceptor] token refreshed", res);

          this.accessToken = res.token;
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(res.token);
        }).catch(error => {
          console.log("[AuthInterceptor] refreshToken failed", error);
          this.accessToken = null;
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(null);
        });
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(token => {
        console.log("[AuthInterceptor] resume request on queue", req.url);

        return next.handle(
          req.clone({
            headers: req.headers.set("Authorization", "Bearer " + token)
          })
        )
      })
    )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error && error.error.message) {
      console.log("[AuthInterceptor] handleError detail:", error.error);

      if (Array.isArray(error.error.message)) {
        return {
          ...error.error,
          message: error.error.message.join(', '),
        }
      }
      return error.error
    } else {
      console.log("[AuthInterceptor] handleError default:", error);

      return {
        status: error.status,
        message: error.message,
        error: error.statusText
      }
    }
  }
}