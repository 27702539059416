import { Pipe, PipeTransform } from '@angular/core';
import { Camera } from '../models/camera/camera';

@Pipe({
  name: 'camera'
})
export class CameraPipe implements PipeTransform {



  transform(camera: Camera, key: string): string {
    return camera[key] ?? '';
  }


}
