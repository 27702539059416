import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';
import { UserService } from '../services/user.service';

@Pipe({
	name: 'user'
})
export class UserPipe implements PipeTransform {

	constructor(private userService: UserService) { }

	transform(user: User | string, key: string): string {
		const matched = this.userService.find(user)
		return matched ? matched[key] : null
	}

}
