declare const __BUILD_VERSION__: string;

export const environment = {
  production: false,
  // version: __BUILD_VERSION__,
  mqtt: {
    server: 'dev.ai.advancti.com',
    protocol: 'wss',
		port: 8080,
    username: 'ubuntu',
    password: '12345Tkl*'
  }
};