import { ProfileStore } from '@advn/web/profile/data-access';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ReplaySubject, map, skipWhile, take, tap } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

export const AuthGuard: CanActivateFn = (
   route: ActivatedRouteSnapshot,
   state: RouterStateSnapshot,
) => {
   const router = inject(Router);

   const profileStore = inject(ProfileStore);
   const authStatus = new ReplaySubject<boolean | null>(1);

   profileStore.getProfile();

   return toObservable(profileStore.isLoggedIn).pipe(
      tap((isLogin) => {
         console.log(isLogin);
      }),
      skipWhile((status) => status == null),
      take(1),
      map((isLogged) => {
         if (!isLogged) {
            router.createUrlTree(['/', 'public']);
         }
         return true;
      }),
   );
};
