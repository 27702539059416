import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';

import { provideAngularSvgIcon } from 'angular-svg-icon';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';

import * as echarts from 'echarts/core';
import { NgxEchartsModule, provideEchartsCore } from 'ngx-echarts';
import { NgIconsModule } from '@ng-icons/core';
import { environment } from '@advn/shared/web/util/environment';
import { AuthApiService } from '@advn/web/webapi/data-access';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { provideNgIconsConfig } from '@ng-icons/core';
import { heroXMark, heroCheckCircle, heroExclamationCircle, heroXCircle, heroTrash, heroPlus } from '@ng-icons/heroicons/outline';
import { heroPencilSolid, heroTrashSolid } from '@ng-icons/heroicons/solid';

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
   hostname: environment.mqtt.server,
   port: environment.mqtt.port,
   protocol: environment.mqtt.protocol === 'wss' ? 'wss' : 'ws',
   username: environment.mqtt.username,
   password: environment.mqtt.password,
   connectOnCreate: true,
};

@NgModule({
   bootstrap: [AppComponent],
   declarations: [AppComponent],
   imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      NgIconsModule.withIcons({
         heroXMark,
         heroCheckCircle,
         heroExclamationCircle,
         heroXCircle
      }),
      RouterModule.forRoot(routes, { useHash: true }),
      MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
      NgxEchartsModule.forRoot({
         echarts: () => import('echarts')
      }),
   ],
   providers: [
      provideAngularSvgIcon(),
      provideNgIconsConfig({
         strokeWidth: '2',
      }),
      provideEchartsCore({ echarts }),
      provideHttpClient(withInterceptorsFromDi()),
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthInterceptor,
         multi: true,
         deps: [AuthApiService, Router],
      },
   ],
})
export class AppModule { }
