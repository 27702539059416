import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from "../services/auth.service";

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

	constructor(
		public http: HttpClient,
		private authService: AuthService
	) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(error => {
			if (error instanceof HttpErrorResponse && error.status === 403) {
				from(this.handle403(request, next));
			}

			return throwError(error);
		}));
	}

	private async handle403(request: HttpRequest<any>, next: HttpHandler) {
		await this.authService.logout();
		location.reload();
	}
}
