import { Pipe, PipeTransform } from '@angular/core';
import { Junction } from '../models/junction';

@Pipe({
	name: 'junction'
})
export class JunctionPipe implements PipeTransform {

	transform(junction: Junction, key: string): string {
		return junction[key] ?? '';
	}

}
