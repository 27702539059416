import { AuthGuard } from '@advn/web/auth/util';
import { Route } from '@angular/router';

export const routes: Route[] = [
   {
      path: 'private',
      canActivate: [AuthGuard],
      loadChildren: () =>
         import('@advn/web/shell/private').then((m) => m.WebShellPrivateModule),
   },
   {
      path: 'public',
      loadChildren: () =>
         import('@advn/web/shell/public').then((m) => m.WebShellPublicModule),
   },
   {
      path: '',
      redirectTo: 'private',
      pathMatch: 'full',
   },
];