// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
declare var require: any;
const isProduction = false;

const config: { api: string; mqtt: { host: string; protocol: string; port: number; username: string; password: string; }; } = {
	api: isProduction ? 'https://ai.advancti.com/api/v1' : `https://078f-60-52-175-124.ngrok-free.app/api/v1`,
	mqtt: {
		host: '192.168.0.235',
		protocol: 'wss',
		port: 8099,
		username: 'ubuntu',
		password: '12345Tkl*'
	}
};

export const environment = {
	production: isProduction,
	api: config.api,
	mqtt: config.mqtt,
	version: require('../../package.json').version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
