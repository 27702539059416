import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog/alert-dialog.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertDialogService {

  private alertDialog: OverlayRef = this.cdkSpinnerCreate();
  private isRunning: boolean = false;
  private spinnerStatusSubject = new Subject<boolean>();
  spinnerStatus$ = this.spinnerStatusSubject.asObservable();
  message: string = 'An unspecified error has occurred';
  icon: string = '';
  type:string = ''

  constructor(
    private overlay: Overlay
  ) { }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: false,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    })
  }

  AlertFail(message:string) {
    this.icon = 'error';
    this.type = 'error';
    this.message = message;
    this.alertDialog.attach(new ComponentPortal(AlertDialogComponent))
  }

  AlertSuccess(message: string) {
    this.icon = 'check_circle';
    this.type = 'success';
    this.message = message;
    this.alertDialog.attach(new ComponentPortal(AlertDialogComponent))
    
  }

  hideAlert() {
    this.isRunning = false;
    this.alertDialog.detach();
    this.spinnerStatusSubject.next(false);
    
  }
}
